import logo from './assets/HandymanLogoWide.webp';
import yelpLogo from './assets/yelpLogo.png';
import facebookLogo from './assets/facebookLogo.png';
import samImage from './assets/samImage_Med_compressed.jpg';
import './App.css';
import { useState } from 'react';

// Bootstrap imports
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import {firebaseConfig} from "./Setup";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'screen_view', {
  firebase_screen: "Home",
  firebase_screen_class: "Home"
});

function App() {
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formNumber, setFormNumber] = useState("");
  const [formBody, setFormBody] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [alertHeading, setAlertHeading] = useState("Error");
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);

  // helper functions
  const resetForms = () => {
    setFormName("");
    setFormEmail("");
    setFormNumber("");
    setFormBody("");
  };

  const formSubmitPressed = () => {
    logEvent(analytics, 'formSubmitClicked', {
      event: 'formSubmitClicked',
    });
    // sanitize
    const sanitizedName = escape(formName);
    const sanitizedEmail = escape(formEmail);
    const sanitizedNumber = escape(formNumber);
    const sanitizedBody = escape(formBody);

    if (sanitizedName.length === 0) {
      setAlertBody("Name cannot be left blank");
      setShowAlert(true);
      return;
    }
    if (sanitizedEmail.length === 0) {
      setAlertBody("Email cannot be left blank");
      setShowAlert(true);
      return;
    }
    if (sanitizedBody.length === 0) {
      setAlertBody("Task cannot be left blank");
      setShowAlert(true);
      return;
    }

    const now = new Date();
    const formattedDate = now.toLocaleDateString();


    const subject = "Work request from " + sanitizedName + " on " + formattedDate;
    const body = sanitizedBody + "%0D%0A%0D%0A"
                  + "Email: " + sanitizedEmail + "%0D%0A"
                  + "Phone Number: " + sanitizedNumber + "%0D%0A"
                  + "Submitted on " + formattedDate;

    // window.location.href = "mailto:SamsHandymanSolutions@gmail.com?subject="
    //                           + subject + "&body=" + body;
    //
    fetch('https://sendemail-nxaqyzmzla-uc.a.run.app/', {
      method: 'POST',
      body: JSON.stringify({
        name: sanitizedName,
        email: sanitizedEmail,
        number: sanitizedNumber,
        subject: subject,
        body: body,
      })
    }).then(response => response.json())
    .then(data => {
      // do something with the data
      console.log("success");
      if (data.success) {
        setFormAlreadySubmitted(true);
        resetForms();

        logEvent(analytics, 'formEmailSentSuccessfully', {
          event: 'formEmailSentSuccessfully',
        });
      }
      else {
        setAlertBody("Failed to submit. Please try again.");
        setShowAlert(true);
        console.log("fail", data);
      }
    })
    .catch(error => {
      // handle the error
      setAlertBody("Failed to submit. Please try again.");
      setShowAlert(true);
      console.log("fail", error);
    });

    // resetForms();
    // setFormAlreadySubmitted(true);
  };



  // builders
  let alert = null;
  if (showAlert) {
    alert = <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
      <Alert.Heading>{alertHeading}</Alert.Heading>
      <p>
        {alertBody}
      </p>
    </Alert>;
  }


  let formXml = <Form className="TextAlignLeft">
    <Form.Group className="mb-3" controlId="formName">
      <Form.Label>Name</Form.Label>
      <Form.Control type="text" placeholder="Enter name" value={formName}
            onChange={(e) => setFormName(e.target.value)}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formEmail">
      <Form.Label>Email</Form.Label>
      <Form.Control type="email" placeholder="Enter email address" value={formEmail}
            onChange={(e) => setFormEmail(e.target.value)}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formNumber">
      <Form.Label>Phone</Form.Label>
      <Form.Control type="text" placeholder="Enter phone number" value={formNumber}
            onChange={(e) => setFormNumber(e.target.value)}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBody">
      <Form.Label>Task</Form.Label>
      <Form.Control as="textarea" rows={3} placeholder="What do you need me to do?" value={formBody}
            onChange={(e) => setFormBody(e.target.value)}/>
    </Form.Group>
    <Button variant="dark" style={{minWidth: "150px"}} onClick={formSubmitPressed}>
      Submit
    </Button>
  </Form>;

  if (formAlreadySubmitted) {
    formXml = <h4>Message submitted. Thank you!</h4>;
  }

  // main return
  return (
    <div className="App">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <Image
              src={logo}
              width="250"
              className="d-inline-block align-top"
              alt="Sam's Handyman Solutions logo"
              rounded
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#AboutUs">About Us</Nav.Link>
              <Nav.Link href="#ContactUs">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid className="HomePage MainContainer" style={{paddingRight: 0, paddingLeft: 0}}>
        {/*}<Row style={{paddingTop: "80px"}}>
          <Col>
            <h1>
              Sam's Handyman Solutions
            </h1>
          </Col>
        </Row>
        <Row style={{paddingTop: "80px", paddingBottom: "80px"}}>
          <Col md="4" />
          <Col md="4">
            <Image className="MainLogo" src={logo} fluid rounded alt="Sam's Handyman Solutions Logo"/>
            <a href="/#ContactUs"><Button variant="dark" style={{minWidth: "150px"}}>Contact Us</Button></a>
          </Col>
          <Col md="4" />
        </Row>*/}

        <div style={{position: "relative"}}>
          <Image className="SectionBGImage" src={samImage} alt="Sam image"/>
          <Row className="SectionWithBGImage">
            <Col md="1" />
            <Col md="10">
              <h2>
                ASSEMBLY &nbsp; | &nbsp; INSTALLATION &nbsp; | &nbsp; MAINTENANCE &nbsp; | &nbsp; REPAIR
              </h2>
              <br/>
              <p>
                QUEENS & NASSAU
              </p>
              <br/>
              <a href="/#ContactUs"><Button variant="dark" style={{minWidth: "150px"}}>Contact Us</Button></a>
            </Col>
            <Col md="1" />
          </Row>
        </div>

        {/* About Section */}
        <Row style={{paddingTop: "80px"}} className="" id="AboutUs">
          <Col>
            <h3>About</h3>
          </Col>
        </Row>
        <Row style={{padding: "20px 20px 20px 20px"}} className="">
          <Col md="1" />
          <Col md="10">
            <p>
              I emphasize professionalism, communication, and reliability.
              If you need anything assembled, installed, maintained, repaired or replaced, I'd be happy to do it for you.
              I'm always honest and straightforward. If I don't know something, I'll let you know.
              But I'll always try my best to help.

            </p>
          </Col>
          <Col md="1"/>
        </Row>
        <Row style={{padding: "40px 30px 80px 30px"}} className="">
          <Col md="3" className="ServicesList">
            <h5>Kitchen</h5>
            <p className="">
              • Cabinets<br/>
              • Garbage Disposals<br/>
              • Refrigerator Maintenance/Repair<br/>
              • Sinks<br/>
            </p>
          </Col>
          <Col md="3" className="ServicesList">
            <h5>Living Room</h5>
            <p>
              • Computer Repair<br/>
              • Curtain/Blinds Installation<br/>
              • Drywall Patch<br/>
              • Furniture Assembly<br/>
              • Smart Home Setup<br/>
              • TV Mounting<br/>
              • Vacuum Repair<br/>
            </p>
          </Col>
          <Col md="3" className="ServicesList">
            <h5>Outdoors</h5>
            <p>
              • Blower Maintenance/Repair<br/>
              • Bicycle Maintenance/Repair<br/>
              • Motorcycle Maintenance/Repair<br/>
              • Lawn Mower Maintenance/Repair<br/>
              • Power Washer Maintenance/Repair<br/>
              • String Trimmer Maintenance/Repair<br/>
            </p>
          </Col>
          <Col md="3" className="ServicesList">
            <h5>Miscellaneous</h5>
            <p>
              • HVAC Maintenance/Repair<br/>
              • Lights/Light Fixtures<br/>
              • Smart Lock Install<br/>
              • Smart Thermostat Install<br/>
              • Smoke Detectors<br/>
              • Toilets<br/>
              • Windows & Doors<br/>
            </p>
          </Col>
        </Row>

        {/* Contact Us Section */}
        <Row style={{paddingTop: "80px"}} id="ContactUs" className="GreyBackground">
          <Col>
            <h3>Contact</h3>
          </Col>
        </Row>
        <Row style={{padding: "60px 20px 80px 20px"}} className="GreyBackground">
          <Col md="3" />
          <Col md="6">
            {formXml}
          </Col>
          <Col md="3"/>
        </Row>
        <Row style={{paddingTop: "80px", paddingBottom: "20px"}}>
          <Col>
            <a href="https://www.facebook.com/profile.php?id=61552080971780">
              <Image className="MediaLogo" src={facebookLogo} fluid alt="Facebook Logo"/>
            </a>
            <a href="https://www.yelp.com/biz/sams-handyman-solutions-new-york">
              <Image className="MediaLogo" src={yelpLogo} fluid alt="Yelp Logo"/>
            </a>
          </Col>
        </Row>
        <Row style={{paddingBottom: "80px"}}>
          <Col>
            <p>
              <a href="mailto:SamsHandymanSolutions@gmail.com">
                <span className="material-symbols-outlined MaterialIcon">
                  mail
                </span>
                SamsHandymanSolutions@gmail.com
              </a>
            </p>
            <p>
              <a href="tel:347-841-6842">
                <span className="material-symbols-outlined MaterialIcon">
                  call
                </span>347-841-6842
              </a>
            </p>
          </Col>
        </Row>
      </Container>

      {alert}
    </div>
  );
}

export default App;
